import React from "react";

import { Progress } from "antd";
import { t } from "../../utilities/Message";
import { ROUTES_GENERAL } from "../../utilities/Constant";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { pathServer } from "../../utilities/Function";
import "./style.scss";

// Define your props here
interface ProgressInfoProps {
  className?: string;
  percentage?: number;
  color?: string;
  title?: string;
  content?: string;
  result?: string;
  size?: number;
  withLink?: boolean;
  strokeWidth?: number;
  status?: number;
  message?: string;
}

const TProgressInfo = (props: ProgressInfoProps) => {
  const {
    percentage,
    color,
    className,
    title,
    content,
    result,
    size = 160,
    withLink = true,
    strokeWidth = 3,
    status,
  } = props;
  return (
    <div className={`ProgressInfo ${props.className}`}>
      {status === 3 ? (
        <ReactSVG
          src={pathServer.PATH_ICONS + "ic_failed.svg"}
          className="icon-large"
        />
      ) : (
        <Progress
          type="circle"
          percent={Math.floor(percentage)}
          size={size}
          strokeColor={color}
          strokeWidth={strokeWidth}
          className={className}
        />
      )}
      {status === 3 ? (
        <div>
          <p className="font-bold ml-1 mt-0">
            ANÁLISIS <br /> FALLIDO
          </p>
          <p className="ml-1 mt-0">
            Este motor está saturado. Se enviará por correo electrónico el
            informe completo.
          </p>
        </div>
      ) : (
        <div className="">
          <p style={{ maxWidth: "200px" }}>
            {title && t(title)}
            <br />
            <strong className="uppercase font-bold">
              {content && t(content)}
            </strong>{" "}
            <br />
            {result && t(result)}
          </p>
        </div>
      )}
    </div>
  );
};

TProgressInfo.displayName = "TProgressInfo";

TProgressInfo.defaultProps = {
  className: "",
};

export default TProgressInfo;
