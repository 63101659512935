import React from "react";
import TButtonLink from "../ButtonLink";
import "./style.scss";
import { pathServer } from "../../utilities/Function";

// Define your props here
interface CtaFooterProps {
  className?: string;
  onClick?: () => void;
}

const TCtaFooter = (props: CtaFooterProps) => {
  return (
    <div className={`CtaFooter ${props.className}`}>
      <img
        width={200}
        src={pathServer.PATH_LOGO + "ic_verifai_pro.svg"}
        alt="verifai "
      />
      <h3 className="white mt-2">
        ¿Quieres saber como VerifAI puede ayudar a tu empresa?
      </h3>
      <TButtonLink
        className="mb-2"
        onClick={props.onClick}
        name="Contáctanos"
      />
      <small className="mt-2 white">
        ¿Ya tienes cuenta en VerifAI PRO? Accede a tu área privada
      </small>
    </div>
  );
};

TCtaFooter.displayName = "TCtaFooter";

TCtaFooter.defaultProps = {
  className: "",
};

export default TCtaFooter;
