import React from "react";

import { Skeleton, Tag } from "antd";

import "./style.scss";
import TProgressInfo from "../../../ProgressInfo";
import { getResults } from "../../../Result/Constants";
import { isTaskLoading } from "../../../../utilities/Function";

// Define your props here
interface MyReportProps {
  id: number;
  className?: string;
  name: string;
  tokens: number;
  content_type: string;
  percentage?: number;
  status?: number;
  message?: string;
  motorTags?: string;
  review?: number;
  onHandleReview?: (review: number, subTaskId: number) => void;
}

const TResultItem = (props: MyReportProps) => {
  const { color, className, ...rest } = getResults(
    props.percentage,
    props.content_type
  );

  return (
    <div
      className={`ResultItem  ${props.className} ${
        isTaskLoading(props.status) ? " Result__loading" : {}
      } `}
    >
      <div>
        <p style={{ fontSize: "1rem" }}>Resultado del motor</p>
        <p style={{ fontSize: "1.2rem" }} className="font-medium-2">
          {props.name}
        </p>
      </div>
      {/* <div className="ResultItem__tags">
        {props.motorTags &&
          props.motorTags.split(",").map((tag) => <Tag>{tag}</Tag>)}
      </div> */}

      {isTaskLoading(props.status) ? (
        <Skeleton avatar paragraph={{ rows: 1 }} active />
      ) : (
        <>
          <TProgressInfo
            size={80}
            strokeWidth={5}
            color={color}
            className={"ResultItem__progress " + className}
            percentage={props.percentage}
            withLink={false}
            status={props.status}
            message={props.message}
            {...rest}
          />
        </>
      )}
      <p>
        En ningún caso el análisis preliminar de VerifAI puede ser utilizado
        como prueba vinculante. Aconsejamos utilizar los resultados de análisis
        como punto de partida y llevar a cabo más investigaciones.
      </p>
    </div>
  );
};

TResultItem.displayName = "TResultItem";

TResultItem.defaultProps = {
  className: "",
};

export default TResultItem;
