// constantes.js

export const TABS_INFO = [
  {
    key: "1",
    tab: "Análisis de videos",
    title: "Qué tipos de video podemos analizar",
    content: `
      - Una sola cara claramente visible. Estamos trabajando en mejorar nuestro modelo para poder detectar videos con múltiples caras.
      - Duración entre 20 y 60 segundos. Necesitamos un mínimo de 20 segundos para tener suficiente material para analizar. Los videos de más de 60 segundos requerirán mucho tiempo, resultando en largas esperas.
      - Formato: puede subir la mayoría de los formatos de video estándar (.mpg, .m4v, .mp4, .mov, .mkv, etc.)

      `,
    uploadInstructions: `
      Puedes adjuntar videos de 2 maneras:
      1. Subiendo un archivo de video desde tu ordenador.
      2. Mediante un enlace de video de YouTube.
    `,
  },
  {
    key: "2",
    tab: "Análisis de imágenes",
    title: "Qué tipos de imágenes podemos analizar",
    content: `
      - Imágenes claras y nítidas. El análisis de imágenes funciona mejor con fotos de alta resolución.
      - Los formatos de imágenes admitidos incluyen .jpg, .png, .bmp, y otros formatos estándar.
    `,
    uploadInstructions: `
      Puedes adjuntar imágenes de la misma manera que los videos, subiendo archivos desde tu dispositivo o insertando enlaces.
    `,
  },
  {
    key: "3",
    tab: "Análisis de audios",
    title: "Qué tipos de audio podemos analizar",
    content: `
      - El análisis de audio admite archivos en formatos .mp3, .wav y .aac.
      - La duración máxima del audio para un análisis es de 5 minutos.
    `,
    uploadInstructions: `
      Suba su archivo de audio directamente o enlace una fuente de audio.
    `,
  },
  {
    key: "4",
    tab: "Resultados de análisis",
    title: "Visualización de resultados",
    content: `
      - Los resultados de análisis estarán disponibles para su visualización una vez completado el proceso.
      - Cada resultado incluye métricas detalladas sobre el análisis realizado.
    `,
    uploadInstructions: `
      Puede descargar los resultados en formato PDF o CSV para un análisis más detallado.
    `,
  },
];
