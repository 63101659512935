import React from "react";

import "./style.scss";
import { pathServer } from "../../utilities/Function";
import { ReactSVG } from "react-svg";
import { t } from "../../utilities/Message";
import { scrollToElementById } from "../../../infraestructure/utils/helpers";

interface HeroProps {
  className?: string;
  title?: string;
  footer?: string;
  description?: string;
  image?: string;
  items?: [string];
}

const THero = (props: HeroProps) => {
  const { description, image, title, footer } = props;

  const onClick = () => {
    scrollToElementById("hero_text");
  };

  return (
    <div className="hero max-padding">
      <div className="hero__content">
        <div className="hero__text" id="hero_text">
          <h2 className="font-bold">{title}</h2>
          <p className="w-90">{description}</p>
          <p className="title font-bold">{footer}</p>
        </div>
        <div className="from-lg">
          <img
            src={image}
            alt="Hero"
            className="hero__top-circle"
            width={400}
            height={400}
          />
        </div>
      </div>
    </div>
  );
};

THero.displayName = "THero";

THero.defaultProps = {
  className: "",
  title: "Learn more",
  subtitle:
    "Content manipulation is one of the greatest dangers AI is posing to our societies today.",
  description:
    " AI-generated content can be used to create convincing fake news, deepfakes, and other types of propaganda, which can spread rapidly on social media and cause harm to individuals, society. Literally in the hands of millions of people, AI tools make it easy to create fake content that can be used to mislead others. Tools like ChatGPT are also likely to disrupt areas like education and journalism where it is crucial know if content has been created by a human or a machine.",
  image: "https://via.placeholder.com/300x300.png",
  footer:
    "VerifAI aims to help users navigate the new world of media and content, increase clarity, form more informed opinions and strengthen our democracies.",
};

export default THero;
