import React from "react";
import { Row, Col } from "antd";

import { pathServer } from "../../utilities/Function";
import TDropZone from "../DropZone";
import { t } from "../../utilities/Message";
import "./style.scss";

interface BannerProps {
  className?: string;
  uploadFile?: (params: File) => void;
  loading: boolean;
}

const TBanner = (props: BannerProps) => {
  return (
    <div className={`banner ${props.className} max-padding`}>
      <Row gutter={[16, 16]} className="max-width">
        <Col className="w-100 text-center">
          <p className="banner__subtitle font-medium-2">
            Detecta <b>contenido </b> generado o manipulado <br /> con{" "}
            <b> inteligencia artificial</b>
          </p>
          <div className="mt-0">
            <small>
              Prueba la versión beta de VerifAI, <br /> la solución ﻿para
              detectar deepfakes
            </small>
          </div>

          <TDropZone loading={props.loading} uploadFile={props.uploadFile} />
        </Col>
      </Row>

      <img
        className="mt-3"
        src={pathServer.PATH_IMG + "banner_home.svg"}
        alt="test"
      />
    </div>
  );
};

TBanner.displayName = "TBanner";

TBanner.defaultProps = {
  className: "",
};

export default TBanner;
