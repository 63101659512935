import React from "react";

import { Collapse, Skeleton, Image } from "antd";

import TResultItem from "../ResultItem";
import "./style.scss";
import TCollapseInfo from "../../../CollapseInfo";
import { fileSizeStr } from "../../../../../infraestructure/utils/helpers";
import { isTaskLoading } from "../../../../utilities/Function";
import MetaData from "../Metadata";

interface MyReportProps {
  loading?: boolean;
  className?: string;
  id?: string;
  data?: any;
  task?: any;
  resource?: any;
}

interface TMyReportHeaderSummaryProps {
  loading: boolean;
  resource: string;
  task: any;
  content_type: string;
  filename?: string;
  filesize?: any;
  mimetype?: any;
  hash?: any;
}

const TMyReportHeaderSummary = React.memo(
  (props: TMyReportHeaderSummaryProps) => {
    const _onPrint = () => {
      window.print();
    };
    return (
      <div className={" MyReportDetailBody__body pb-1 "}>
        <MetaData typeFile={props.content_type} loading={props.loading} />

        <div className="m-auto">
          <div className="d-flex  pb-1">
            <div className="MyReportDetailBody__header w-100">
              <h1 className="font-bold">Resumen</h1>
              <p
                className="ml-auto underline font-bold blue pointer"
                onClick={_onPrint}
              >
                Descargar Informe
              </p>
            </div>
          </div>
          <div className="d-flex mt-2">
            <p className="font-bold">Nombre del archivo: </p>{" "}
            <strong>{props.filename}</strong>
          </div>
          <div className="d-flex">
            <p className="font-bold"> Tamaño :</p>{" "}
            <strong>{fileSizeStr(props.filesize)}</strong>
          </div>
          <div className="d-flex">
            <p className="font-bold"> Formato : </p>{" "}
            <strong>{props.mimetype}</strong>
          </div>
          <div className="d-flex">
            <p className="font-bold"> Hash : </p>{" "}
            <strong className="hash">{props.hash}</strong>
          </div>
          <div className="mt-2">
            <p>
              En ningún caso el análisis preliminar de VerifAI puede ser
              utilizado como prueba vinculante. Aconsejamos utilizar los
              resultados de análisis como punto de partida y llevar a cabo más
              investigaciones.
            </p>
          </div>
        </div>
      </div>
    );
  }
);

const TMyReportDetailBody = (props: MyReportProps) => {
  const { task } = props;

  const subTaks =
    task &&
    task?.subtasks &&
    task?.subtasks
      ?.filter((d) => d.detailed_result.meta_engine !== true)
      .sort((a, b) => a.updated_at > b.updated_at);

  const items = subTaks.map((d, index) => {
    return {
      key: d.id,
      label: (
        <TResultItem
          motorTags={d.algorithm_tags}
          name={d.algorithm}
          tokens={d.token ? d.token : "-"}
          percentage={d.ai_score}
          content_type={task.content_type}
          status={d.status}
          message={d.message}
          review={d.review}
          id={d.id}
        />
      ),
    };
  });
  return (
    <div className={`MyReportDetailBody  ${props.className}`} id={props.id}>
      <TMyReportHeaderSummary
        loading={props.loading}
        resource={props.resource}
        task={props.task}
        content_type={props.task.content_type}
        filename={props.task.filename}
        filesize={props.task.filesize}
        mimetype={props.task.mimetype}
        hash={props.task.hash}
      />
      <h3 className="font-bold mt-2 mb-0">Detalles</h3>
      <Collapse items={items} defaultActiveKey={["0"]} />
    </div>
  );
};

TMyReportDetailBody.displayName = "TMyReportDetailBody";

TMyReportDetailBody.defaultProps = {
  className: "",
};

export default TMyReportDetailBody;
