import React from "react";

import { ApiRespone, FilesAvailable } from "../../../domain/entities/Api";
import { getResults } from "./Constants";
import { useHistory } from "react-router-dom";

import PaymentContent from "./components/PaymentContent";
import FreeContent from "./components/FreeContent";
import "./style.scss";

interface ResultProps {
  className?: string;
  loading: boolean;
  data: ApiRespone;
  doPayment?: () => void;
  isPaymentProgress: boolean;
  waitingSyncPayment?: boolean;
  id?: string;
}

const TResult = (props: ResultProps) => {
  const { data, loading, isPaymentProgress } = props;
  const history = useHistory();

  const percentage =
    data?.subtasks?.length > 0 ? data.subtasks[0].ai_score : "-";

  const typeFile = data?.content_type || localStorage.getItem("typeFile");

  const { color, className, title, content, result } = getResults(
    percentage,
    typeFile
  );

  const _onCancel = () => {
    history.goBack();
  };

  return (
    <div id={"result"} className={`result ${props.className}`}>
      {data.content_type === FilesAvailable.video ? (
        <PaymentContent
          doPayment={props.doPayment}
          loading={loading}
          isPaymentProgress={isPaymentProgress}
          data={data}
          typeFile={typeFile}
          onCancel={_onCancel}
          waitingSyncPayment={props.waitingSyncPayment}
        />
      ) : (
        (data.content_type === FilesAvailable.image ||
          data.content_type === FilesAvailable.audio) && (
          <FreeContent
            percentage={percentage}
            color={color}
            className={className}
            title={title}
            content={content}
            result={result}
            typeFile={typeFile}
            onCancel={_onCancel}
            loading={loading}
          />
        )
      )}
    </div>
  );
};

TResult.displayName = "TResult";

TResult.defaultProps = {
  className: "",
};

export default TResult;
