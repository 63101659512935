import React, { useContext, useEffect } from "react";
import { withLanding } from "../../hocs/withLanding";
import { page_view } from "../../hooks/usePageView";
import { EVENTS_TGM } from "../../utilities/Events";
import { LanguageContext } from "../../context/i18n/LanguageProvider";
import { Tabs, Typography, Upload, Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { TABS_INFO } from "./Constants"; // Importamos la info de las pestañas

import "./styles.scss";

const { Title, Paragraph } = Typography;
const { TabPane } = Tabs;

const HelpPage = () => {
  const { locale } = useContext(LanguageContext);

  useEffect(() => {
    page_view({ page_type: EVENTS_TGM.conditions, locale });
  }, []);

  return (
    <div className="help-page">
      <div className="max-width help_content">
        <Title level={2}>Ayuda</Title>
        <Tabs defaultActiveKey="1">
          {TABS_INFO.map(({ key, tab, title, content, uploadInstructions }) => (
            <TabPane tab={tab} key={key}>
              <Title level={4}>{title}</Title>
              <Paragraph>{content}</Paragraph>
              <Paragraph>{uploadInstructions}</Paragraph>
            </TabPane>
          ))}
        </Tabs>
      </div>
    </div>
  );
};

export default withLanding(HelpPage);
